import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom'


function SignUp() {
    const [userName, setUserName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const nav = useNavigate()


    const signUp = () => {
        if (!userName)
            return toast.error("Empty username field !", { autoClose: 2000 })
        if (!email)
            return toast.error("Empty email field !", { autoClose: 2000 })
        if (!password)
            return toast.error("Empty password field !", { autoClose: 2000 })


        axios.post(`${process.env.REACT_APP_API_URL}/api/user/signUp`, {
            userName,
            email: email,
            password: password
        })
            .then(res => {
                nav("/login")
            })
            .catch(err => {
                toast.error(err.response.data.err ?? 'Something wrong', { autoClose: 2000 })
            })
    }

    return (
        <div className="max-w-[500px] min-h-[100vh]  mx-auto flex justify-center items-center relative  " style={{
            background: ` linear-gradient(180deg, rgba(49,50,82,0) 0%, rgba(106,62,187,1) 50%, rgba(106,62,187,1) 100%, rgba(106,62,187,1) 100%)`
        }}>
            <ToastContainer />
            <img src="./assets/gabin-vallet-XUn7yMZH2yg-unsplash.jpg" alt="background" className="absolute -z-10 object-cover w-full h-full" />
            <div className="w-[90%] h-full  font-aeonik">
                <div className="flex justify-between items-center">
                    <button type='button' className='text-white w-[38px] h-[38px] rounded-full flex justify-center mt-[69px] items-center bg-white bg-opacity-35 backdrop-blur-[30px] ' onClick={() => nav('/')}><i className="fa-solid fa-arrow-left"></i></button>
                    <img src="./assets/tryfit-logo.png" alt="TryFit logo" className="w-[158px] mt-[69px]" />
                    <div></div>
                </div>
                <div className="mt-[25px]">
                    <h1 className="text-[50px] font-bold text-white">Create your <br />
                        account</h1>
                    <p className="text-[15px] text-white mt-2 font-euclid_circular">Join TryFit Today - Your Fitness Journey <br />
                        Starts Here!
                    </p>
                    <div className="flex flex-col mt-[39px]">
                        <label htmlFor="Username" className="text-[14px] text-[#B59FDD]">Username</label>
                        <input id="Username" type="text" className="flex-1 bg-transparent outline-none text-[18px] text-white !border-b border-b-[#BDC4CC] py-[9px]" value={userName} onChange={(e) => setUserName(e.target.value)} />
                    </div>
                    <div className="flex flex-col mt-[17px]">
                        <label htmlFor="email" className="text-[14px] text-[#B59FDD]">Email</label>
                        <input id="email" type="email" className="flex-1 bg-transparent outline-none text-[18px] text-white !border-b border-b-[#BDC4CC] py-[9px]" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="flex flex-col mt-[17px]">
                        <label htmlFor="password" className="text-[14px] text-[#B59FDD]">Password</label>
                        <input id="password" type="password" className="flex-1 bg-transparent outline-none text-[18px] text-white !border-b border-b-[#BDC4CC] py-[9px]" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className="flex justify-end mt-[39px]">
                    </div>
                    <div className="mb-[89px]">
                        <button type="button" className="block text-center w-full py-3 text-white text-[20px] rounded-[10px] bg-[#BAA5E1] mt-[42px]" onClick={signUp} ><i class="fa-solid fa-user-plus"></i> Sign up</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUp;
