import React, { useEffect, useState } from 'react'
import NavBar from '../components/NavBar'
import { Link } from 'react-router-dom'
import checkAuth from '../middleware/checkAuth'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Loading from './Loading'
import Header from '../components/Header'


const Program = () => {
    const [user, setUser] = useState(localStorage.user ? JSON.parse(localStorage.user) : null)
    const nav = useNavigate()
    const [programs, setPrograms] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)
    const [activities, setActivities] = useState({})

    const getData = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/program`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            setPrograms(res.data)

            const resActivity = await axios.get(`${process.env.REACT_APP_API_URL}/api/dailyActivity/${user._id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.token}`
                }
            })
            setActivities(resActivity.data)
            setDataLoaded(true)
        } catch (err) {
            toast.error(err.response.data.err ?? 'Something wrong', { autoClose: 2000 })
        }
    }

  

    useEffect(() => {
        const authentication = async () => {
            let isAuth = await checkAuth()
            if (isAuth) {
                getData()
            } else {
                nav('/login')
            }
        }
        authentication()
    }, [])



    const totalDays = (program) => {
        let total = 0
        program.calender.map((elt) => total += elt.exercices.length)
        return total
    }

    return (
        dataLoaded ? <div className="relative max-w-[500px] min-h-[100vh]  mx-auto  overflow-hidden pb-[110px]   " style={{
        }}>


           

            {/* <DownloadBtn/> */}

            <ToastContainer />
            <div className='absolute top-0 -z-10  w-full h-[50%] bg-white opacity-90'>
                <img src="./assets/victor-freitas-hOuJYX2K5DA-unsplash.jpg" alt="background" className=" w-full object-cover opacity-100 blur-lg -z-10 " />
            </div>
            <Header/>
            <div className='w-full rounded-t-[30px] min-h-[100vh] mt-[82px]' style={{ background: `linear-gradient(180deg, rgba(83,49,146,1) 0%, rgba(59,35,104,1) 100%)` }}>
                <div className='w-[90%] pt-[53px] m-auto space-y-[35px]' >

                    {programs.map((elt, index) => <Link key={index} to={`/calender/${elt.id}`} className='block'>
                        <div className='w-[100%] h-[172px] rounded-[15px] overflow-hidden flex items-end relative'>
                            <img src={`${process.env.REACT_APP_API_URL}/${elt.image.path}`} alt='background' className='absolute w-[100%] h-[172px] object-cover' />
                            <div className='w-full h-[107px] z-30 text-white px-[22px] pt-[34px]' style={{ background: `linear-gradient(180deg, rgba(255,255,255,0.01) 0%, rgba(186,165,225,1) 100%)` }}>
                                <h3 className='font-Montserrat text-xl mb-[11px]'>{elt.title}</h3>
                                <div className='flex gap-5'>
                                    <p className='font-Montserrat text-sm flex justify-start items-center gap-3'>
                                        <img src="./assets/Group 2369.png" alt="" />
                                        {elt.level}</p>
                                    <p className='font-Montserrat text-sm flex justify-start items-center gap-3'>
                                        {totalDays(elt)} days</p>
                                </div>
                            </div>
                        </div>
                    </Link>)}
                </div>
            </div>
            <NavBar />
        </div>
            : <Loading />

    )
}

export default Program